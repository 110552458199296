/* eslint-disable no-undef */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';
import store from '../store';
import quizzesList from '../views/quizzes/list.vue'
// import jwt from 'src/jwt';

Vue.use(VueRouter);

const routes = [
    //dashboard
    { path: '/', redirect: '/users' },
    { path: '/dashboard', name: 'Home', component: Home },
    {
        path: '/index2',
        name: 'index2',
        component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
        meta: { layout: 'auth' }
    },
    // User Componnents
    {
        path: '/users',
        // name: 'users',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/router-view.vue'),
        children: [
            {
                path: '',
                name: 'users-list',
                // component: quizzesList
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/users/list.vue')
            },
            {
                path: 'quizzes',
                // name: 'quizzes-list',
                // component: quizzesList
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/quizzes/list.vue')
            },

            {
                path: 'list',
                name: 'user_list',

                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/users/list.vue')
            },
            {
                path: 'engagement-quota',
                name: 'engagement-list',
                component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/engQuota/list.vue')
            },
            {
                path: 'detail/:id',
                name: 'user-profile',
                component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/detail.vue'),
                props: true
            }
        ]
    },

    {
        path: '/plan/web',
        // name: 'web-plan',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/router-view.vue'),
        children: [
            {
                path: '',
                name: 'web-plan-list',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/plans/webPlans.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditWebPlan',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/plans/editWebPlans.vue')
            },
            {
                path: 'add',
                name: 'AddWebPlan',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/plans/addPlan.vue')
            }
        ]
    },

    {
        path: '/templates',
        // name: 'templates',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/router-view'),
        children: [
            {
                path: '',
                name: 'template-list',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/templates/list.vue')
            },
            {
                path: 'unlayer-templates',
                name: 'UnlayerTemplates',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/templates/UnlayerTemplates.vue')
            },
            {
                path: 'categories',
                name: 'TemplatesCategoryList',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/templates/category/list.vue')
            }
        ]
    },
    {
        path: '/env-const',
        // name: 'templates',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/router-view'),
        children: [
            {
                path: '',
                name: 'template-list',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/EnvConst/list.vue')
            }
        ]
    },
    {
        path: '/countries',
        name: 'country-list',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/country/list.vue')
    },
    {
        path: '/languages',
        name: 'language-list',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/language/list.vue')
    },
    {
        path: '/font-family',
        name: 'font-family',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/fontFamily/list.vue')
    },

    {
        path: '/errors',
        name: 'errors-list',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/Errors/list.vue')
    },
    {
        path: '/roles/add',
        meta: { title: 'Add Role' },
        name: 'add-permission',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/Roles/components/permissions.vue')
    },
    {
        path: '/roles/edit/:id',
        meta: { title: 'Edit Role' },
        name: 'edit-permission',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/Roles/components/permissions.vue')
    },
    {
        path: '/roles',
        meta: { title: 'Role List' },
        name: 'list-permission',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/Roles/list.vue')
    },

    {
        path: '/backup',
        name: 'backup-list',
        component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/backup/list.vue')
    },

    {
        path: '/integration',
        // name: 'integration-list',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/router-view.vue'),
        children: [
            {
                path: '',
                name: 'integration-list',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/integration/list')
            },
            {
                path: 'add',
                name: 'integration-add',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/integration/components/add.vue')
            },
            {
                path: 'edit/:id',
                name: 'integration-edit',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/integration/components/edit.vue'),
                props: true
            },
            {
                path: 'categories',
                name: 'integration-category-list',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/integration/integration-category/list.vue')
            }
        ]
    },
    {
        path: '/webhook',
        // name: 'integration-list',
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/router-view.vue'),
        children: [
            {
                path: '',
                name: 'integration-list',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/Webhook/list')
            },
            {
                path: 'add',
                name: 'integration-add',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/Webhook/components/add.vue')
            },
            {
                path: 'edit/:id',
                name: 'integration-edit',
                component: () => import(/* webpackChunkName: "components-tabs" */ '../views/Webhook/components/edit.vue'),
                props: true
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {

   
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    if (to.name !== 'Login' &&  !jwt.getToken()) {
        {
            store.commit('setLayout', 'auth');
            next({ name: 'Login' })}
        }
        else next()

});

export default router;
