
<template>
    <div class="layout-px-spacing">

<portal to="breadcrumb">
    <ul class="navbar-nav flex-row">
        <li>
            <div class="page-header">
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <!-- <li class="breadcrumb-item"><a href="javascript:;">Users</a></li> -->
                        <li class="breadcrumb-item active" aria-current="page"><span>Users/Quizzes ({{quizzes.length}})</span></li>
                    </ol>
                </nav>
            </div>
        </li>
    </ul>
</portal>

<!-- {{ users}} -->

<div class="row layout-top-spacing">
    <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div class="panel br-6 p-0 mt-5">
            <div class="custom-table ">
                <div class="table-header">
                    <div class="d-flex align-items-center">
                    <div class="header-search">
                        <b-input v-model="searchFilter" size="sm" placeholder="Search..." />
                        <div class="search-image">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </div>
                       
                    </div>
                    <div class="spiner ml-2" v-show="loader">
                                    <b-spinner variant="primary" small label="Small Spinner"
                                        style="height:20px; width:20px"></b-spinner>
                                    <span class="ml-2">Loading Quizzes...</span>
                                </div>
                </div>
                    <!-- <add-modal  :callbackFunction="getQuizzes" :callbackToast="showToastN"></add-modal> -->
                </div>
               
                <b-table ref="basic_table1" id="my-table" responsive :items="quizzes" :fields="columns"
                   
                     sort-by="name" :show-empty="true"
                    >
                    <template v-slot:cell(user_name)="row">
                               <P>{{ row.item.user_name }}</P>
                               <P>{{ row.item.user_email }}</P>
                            </template>
                            <template v-slot:cell(status)="row">
                                {{ row.item.status==1?'Active':'Draft' }}
                              
                            </template>
                    <template #cell(action)="row">
                        <b-dropdown :right="true" variant="icon-only" toggle-tag="a" class="custom-dropdown">
                            <template #button-content>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-more-horizontal"
                                >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                            </template>
                           
                            <b-dropdown-item @click="$router.push('/users/detail/'+row.item.id)">User Detail</b-dropdown-item>
                            <b-dropdown-item @click="resetAnalysis(row.item.id)">Reset Analysis</b-dropdown-item>
                            <!-- <b-dropdown-item @click="showEditBrandModal(row.item.id)">Edit</b-dropdown-item> -->
                                    <b-dropdown-item @click="destroy(row.item.id)">Delete</b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template #empty="scope">
                                <b-skeleton-table v-if="isLoadingFirstTime" :rows="5" :columns="7"
                                    :table-props="{ bordered: true }"></b-skeleton-table>
                                <div v-else class="d-flex justify-content-center">No Quiz Found!
                                </div>
                            </template>
                </b-table>

                <div class="table-footer justify-content-center" v-if="quizzes.length>0">
                            <div class="paginating-container pagination-solid flex-column align-items-right">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li :class="[page.active ? 'active' : '', 'page-item']"
                                            :style="[page.url ? { cursor: 'pointer' } : { cursor: 'no-drop' }]"
                                            v-for="page in paginationLinks" :key="page.label"
                                            @click="getQuizzes(page.url + '&search=' + searchFilter)">
                                            <a class="page-link" v-html="page.label"></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
            </div>
        </div>
    </div>
</div>

<!-- <edit-modal :brandDetail="editObject" :callbackFunction="getQuizzes" :callbackToast="showToastN"></edit-modal> -->

</div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapActions } from 'vuex';
// import addModal from './components/add.vue'
// import EditModal from './components/edit.vue';
export default {
    metaInfo: { title: 'Countries List' },
    data() {
        return {
            isLoadingFirstTime: true,
            searchFilter:'',
            paginationLinks:[],
            editObject: {},
            table_option: { total_rows: 0, current_page: 1, page_size: 7, search_text: '' },
            loader: true,
            quizzes: [],
            meta1: {},
            columns: [],
            search_text:'',
        };
    },
    watch: {
        searchFilter(newValue) {
            let search = ''
            if (newValue != '')
                search = newValue.trim()
            this.getQuizzes('quizzes/list?search=' + search)
        }
    },
    mounted() {

        this.getQuizzes('quizzes/list');
    },
    methods: {
        destroy(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loader=true;
                    // eslint-disable-next-line no-undef
                    axios.get('quizzes/delete/' + id).then(resp => {
                        // eslint-disable-next-line no-unused-vars
                        if(resp.status===200){
                            Swal.fire(
                            'Deleted!',
                            'Record has been deleted.',
                            'success'
                        )
                        
        this.getQuizzes('quizzes/list');
                        } 
                        else
                        {
                            Swal.fire(
                            'Oops!',
                            'Somthing Went Wrong.',
                            'Danger'
                        )
                        }
                    }).catch(error => { console.log(error); })
                    this.toast.show = false
                    this.loader=false;

                    Swal.fire(
                            'Oops!',
                            'Somthing Went Wrong.',
                            'Danger'
                    )
                }
            })

        },
        resetAnalysis(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Want to reset Quiz",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, reset it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loader=true;
                    // eslint-disable-next-line no-undef
                    axios.post('quizzes/reset/analysis',{"quizId":id}).then(resp => {
                        // eslint-disable-next-line no-unused-vars
                        if(resp.status===200){
                            Swal.fire(
                            'Success!',
                            'Quiz has been restored',
                            'success'
                        )
                        
        this.getQuizzes('quizzes/list');
                        } 
                        else
                        {
                            Swal.fire(
                            'Oops!',
                            'Something Went Wrong.',
                            'Danger'
                        )
                        }
                    }).catch(error => { console.log(error); 
                   
                    Swal.fire(
                            'Oops!',
                            'Something Went Wrong.',
                            'Danger'
                )})
                
                }
            })

        },
        callBackFun() {
            this.getUsers();
        },
        async showEditBrandModal(id) {
            // eslint-disable-next-line no-undef
            axios.get('quizzes/edit/' + id).then(resp => {
                this.editObject = resp.data.data;
                this.$bvModal.show("editBrandModal")
            }).catch(error => {
                this.callbackToast('toast-danger','Something went wrong...')
                        console.log(error);
            })
        },
        async getQuizzes(url) {
            this.loader = true;
           
            const check =  await this.getList(url);
            if(check)
            {
                
                this.quizzes = check.data.data.data;
                this.paginationLinks=check.data.data.links;
                this.columns = [
                { key: 'title', label: 'Title', sortable: true, class:'text-center' },
                { key: 'user_name', label: 'User Name/Email', sortable: true,},
                { key: 'quiz_key', label: 'Quiz Key',sortable: true, },
                { key: 'status', label: 'Status', image: true,class:'text-center',sortable: true, },
                { key: 'action', label: 'Actions', class: 'actions text-center' }
            ];
            }
            this.loader = false;
            this.isLoadingFirstTime=false
        },
        checkLenght() {
            this.table_option.total_rows = this.quizzes.length;
            this.get_meta();
        },
        bind_data() {
            this.table_option.total_rows = this.quizzes.length;
            this.get_meta();
            

        },
        //table 2
        on_filtered1(filtered_items) {
            this.refresh_table1(filtered_items.length);
        },
        view_row1(item) {
            alert('ID: ' + item.id + ', Name: ' + item.name);
        },
        refresh_table1(total) {
            this.table_option.total_rows = total;
            this.table_option.currentPage = 1;
        },
        get_meta() {
            var startPage;
            var endPage;
            var totalPages = this.table_option.page_size < 1 ? 1 : Math.ceil(this.table_option.total_rows / this.table_option.page_size);
            totalPages = Math.max(totalPages || 0, 1);

            var maxSize = 5;
            var isMaxSized = typeof maxSize !== 'undefined' && maxSize < totalPages;
            if (isMaxSized) {
                startPage = Math.max(this.table_option.current_page - Math.floor(maxSize / 2), 1);
                endPage = startPage + maxSize - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = endPage - maxSize + 1;
                }
            } else {
                startPage = 1;
                endPage = totalPages;
            }
            let startIndex = (this.table_option.current_page - 1) * this.table_option.page_size;
            let endIndex = Math.min(startIndex + this.table_option.page_size - 1, this.table_option.total_rows - 1);

            var pages = Array.from(Array(endPage + 1 - startPage).keys()).map(i => startPage + i);
            this.meta1 = {
                total_items: this.table_option.total_rows,
                current_page: this.table_option.current_page,
                page_size: this.table_option.page_size,
                total_pages: totalPages,
                start_page: startPage,
                end_page: endPage,
                start_index: startIndex,
                end_index: endIndex,
                pages: pages
            };
        },
        ...mapActions(['getList', 'addRecord', 'getEdit'])
    }
};
</script>
