function setToken(token){
    localStorage.setItem('bearerToken',token)

}

function getToken(){
   return  localStorage.getItem('bearerToken');
}

function removeToken(){
  localStorage.removeItem('bearerToken');
}


export default {setToken , getToken , removeToken};
