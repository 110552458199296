<template>
    
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-bind:is="layout"></component>
       
    </div>
</template>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import toastMixin from './mixins/toastMixin'
    import '@/assets/sass/app.scss';
    import "vue-toastification/dist/index.css";
    import { mapState } from "vuex";
    export default {
        mixins:[toastMixin],
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s | Quizell Admin'
        },
        components: {
            app: appLayout,
            auth: authLayout, 
                },
        computed: {
            layout() {
                return this.$store.getters.layout;
            },
            ...mapState(['notification'])
            
           
       
        },
        data() {
            return {};
        },
        mounted() {
            // this.toastSuccess('adasdas')
    },
        methods: {},
  watch: {
    notification: {
    handler(newValue) {
      newValue.type==='success'?this.toastSuccess(newValue.text):this.toastError( newValue.text)
      
    },
    deep: true,
  },
},
    };
</script>
