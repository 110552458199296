import axios from './axios'

axios.interceptors.request.use(function(config) {
  const user_access_token = localStorage.getItem("bearerToken")
if (user_access_token) {
  config.headers.Authorization = `Bearer ${user_access_token}`;
}
return config;
});


axios.interceptors.response.use(
  async function(response) {
   
    return response;
  },
 
  async function(error) {
  
    // const originalRequest = error.config;
    if (
      error.response.status === 401 
    ) {
        localStorage.removeItem('user')
        localStorage.removeItem('bearerToken')
        window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);


